import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import Sortable from "sortablejs";
import 'ckeditor4';
// import autosize from "autosize";

/**
 * Controller for story posts.
 *
 * Each story post is a collection of images + captions. Some captions might be
 * empty.
 *
 * Connects to data-controller="influencer--story-post-form"
 */
export default class extends Controller {
  static values = {
    addAssetUrl:          { type: String,  default: '' },    // URL where uploaded assets are sent.
    removeAssetUrl:       { type: String,  default: '' },    // URL to call when removing an asset.
    updatePositionsUrl:   { type: String,  default: '' },    // The URL for updating caption ordering.
    contentId:            { type: Number,  default: 0 },     // ID of the content assets are attached to.
    allowMultipleUploads: { type: Boolean, default: false }, // Allow multiple uploads at a time?
    maxFiles:             { type: Number,  default: 1 },     // Max files that can be uploaded in one go.
  };

  static targets = [
    "input",
    "placeholder",
    "template",
    "rowTemplate",
    "form",
    "videoPreview",
    "caption",
    "frames",
  ];

  /**
   * Form setup.
   *
   * Initialize dropzone, set-up auto-sizing, and make the list sortable.
   */
  connect() {
    super.connect();
    this.editors = [];

    // Disable dropzone auto discovery because it breaks things.
    Dropzone.autoDiscover = false;

    // Set up dropzone area.
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();

    // Create sortable elements.
    this.sortable = Sortable.create(this.framesTarget, {
      group:         'shared',
      animation:     150,
      onEnd:         this.saveCaptionOrder.bind(this),
      selectedClass: 'is-selected', // This doesn't work?
      filter:        '.is-placeholder',
      handle:        '.handle',
    });

    // Add CKEditor to all captions.
    this.captionTargets.forEach((caption) => {
      // TODO: Extract.
      let editor = CKEDITOR.replace(caption, {
        toolbarStartupExpanded : false,
        removePlugins: 'elementspath, lite',
        toolbarGroups: [],
      });

      this.editors.push(editor);
    });

    // Refresh the image list.
    this.maybeShowPreviewBox();
  }

  disconnect() {
    this.dropZone.destroy();
  }


  // ----------------------------------------------------------------------
  // -- Events
  // ----------------------------------------------------------------------

  addRow(file) {
    // Add a new row to the list of frames.
    var addedRow = this.rowTemplateTarget.content.cloneNode(true);

    // Set the placeholder image.
    addedRow.querySelector('.col-2').appendChild(file.previewElement);

    // Count captions so far.
    let captionCount = this.getStoryFrames().length;
    let newId        = captionCount;

    // Replace the term "NEW_RECORD" with new id. This is not the ID of the
    // content caption (that is stored in a hidden field).
    let caption      = addedRow.querySelector('#content_item_story_post_content_captions_attributes_NEW_RECORD_caption');
    caption.setAttribute('name', caption.getAttribute('name').replace('NEW_RECORD', newId));
    caption.setAttribute('id', caption.getAttribute('id').replace('NEW_RECORD', newId));

    let captionId   = addedRow.querySelector('#content_item_story_post_content_captions_attributes_NEW_RECORD_id');
    captionId.setAttribute('name', captionId.getAttribute('name').replace('NEW_RECORD', newId));
    captionId.setAttribute('id', captionId.getAttribute('id').replace('NEW_RECORD', newId));

    // Insert the row.
    this.framesTarget.appendChild(addedRow);

    let editor = CKEDITOR.replace(caption, {
      toolbarStartupExpanded : false,
      removePlugins: 'elementspath, lite',
      toolbarGroups: [],
    });

    this.editors.push(editor);
  }

  saveCaptionOrder(event) {
    // Get updated frame order.
    let order = this.getCaptionOrder();

    // Create payload.
    let formData = new FormData();
    order.forEach(i => {
      formData.append('ordering[]', i);
    });

    // Send to backend.
    let xhr = new XMLHttpRequest();
    xhr.open("PATCH", this.updatePositionsUrlValue, true);
//    xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
    xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));
    xhr.send(formData);
  }

  triggerAddFile(event) {
    this.dropZone.hiddenFileInput.click();
  };

  /**
   * Remove an attached file that was previously added.
   *
   * This is slightly different from the main dropzone delete as it needs to
   * manually remove its own element.
   */
  triggerRemoveFile(event) {
    event.preventDefault();
    event.stopPropagation();

    // TODO: Find a better way to do this. Should not be building requests like this.
    let xhr = new XMLHttpRequest();
    xhr.open("DELETE", event.target.href, true);
    xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));

    // Remove the image.
    event.target.closest('.dz-image-preview').remove();

    // Refresh the image list.
    this.maybeShowPreviewBox();

    // Send the request
    xhr.send();
  };

  maybeShowPreviewBox() {
    // Count items
    if (this.framesTarget.querySelectorAll('.story-post-frame').length == 0) {
      this.showPreviewBox();
    } else {
      this.hidePreviewBox();
    }
  }

  showPreviewBox() {
    // TODO: preview item should be a dedicated target.
    this.placeholderTarget.style.display = 'block';
  }

  hidePreviewBox() {
    // TODO: preview item should be a dedicated target.
    this.placeholderTarget.style.display = 'none';
  }



  // ----------------------------------------------------------------------
  // -- Internal functions
  // ----------------------------------------------------------------------

  getStoryFrames() {
    return this.framesTarget.querySelectorAll('.story-post-frame:not(.story-post-frame--placeholder)');
  }

  getCaptionOrder() {
    return Array.from(this.getStoryFrames()).map((element, i) => {
      return element.getAttribute('data-caption-id');
    }).filter(Number);
  }

  bindEvents() {
    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);

      // Remove the file from the backend.
      // TODO: This should be extracted to a single place as it's used in a bunch of controllers/
      let assetId  = file.previewElement.getAttribute('data-asset-id');
      let assetUrl = this.removeAssetUrlValue.replace(':ASSET_ID', assetId);

      // Remove the file on the backend.
      let xhr = new XMLHttpRequest();
      xhr.open("DELETE", assetUrl, true);
      xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));
      xhr.send();

      // Toggle the preview controller.
      this.maybeShowPreviewBox();
    });

    this.dropZone.on("addedfile", file => {
      // Make the "remove" button prettier.
      file.previewElement.querySelector('.dz-remove').classList.add('btn', 'btn-sm', 'btn-danger');

      // If video, generate a preview image.
      if (this.videoPreviewTarget.canPlayType(file.type)) {
        const fileURL = URL.createObjectURL(file);
        this.videoPreviewTarget.src = fileURL;

        // Set initial placeholder image
        file.previewElement.querySelector('img').src = '/empty.png';

        // Create a preview generator.
        let refreshPreview = () => {
          let canvas = document.createElement('canvas');
          canvas.width  = 186;//this.videoPreviewTarget.videoWidth;
          canvas.height = 256;//this.videoPreviewTarget.videoHeight;

          canvas.getContext('2d').drawImage(this.videoPreviewTarget, 0, 0, canvas.width, canvas.height);
          var dataURI = canvas.toDataURL();

          this.dropZone.emit('thumbnail', file, dataURI);

          // Clear everytihng out.
          URL.revokeObjectURL(file);
          this.videoPreviewTarget.src = '';

          this.videoPreviewTarget.removeEventListener('loadeddata', refreshPreview);
        };

        // Render the preview once the video element has loaded some data.
        this.videoPreviewTarget.addEventListener('loadeddata', refreshPreview);
      }

      // If no images have been uploaded yet, modify the existing image.
      // TODO: Fix this
      /*
      var firstThumbnail = this.placeholderTarget.querySelector('.is-placeholder');
      if (firstThumbnail.style.display != 'none') {
        alert('oi');
        // Hide the placeholder.
        firstThumbnail.style.display = 'none';

        // Move the thumbnail here.
        firstThumbnail.after(file.previewElement);

        // Do nothing else.
        return;
        }
        */

      // Otherwise add a new row.
      this.addRow(file);
//      var addedRow = this.rowTemplateTarget.content.cloneNode(true);

      // Set the placeholder image.
//      addedRow.querySelector('.col-2').appendChild(file.previewElement);

      // Insert the row.
//      this.placeholderTarget.appendChild(addedRow);
    });

    // Set Rails identifiers after a succesful upload.
    this.dropZone.on("success", (file, response) => {
      file.previewElement.setAttribute('data-asset-id', response.asset_id);

      // Get closest container.
      let parent = file.previewElement.closest('.story-post-frame');

      // Update caption id for re-ordering.
      parent.setAttribute('data-caption-id', response.caption_id);

      // Update the caption record id.
      let hiddenField = parent.querySelector("input[type='hidden']");
      hiddenField.setAttribute('value', response.caption_id);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('sending', (file, xhr, formData) => {
      formData.append('content_id', this.contentIdValue);
    });
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  // TODO: Move to a mixin.
  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.addAssetUrlValue;
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 512;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFilesValue,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    previewsContainer: controller.placeholderTarget,
    previewTemplate: controller.templateTarget.innerHTML,
    thumbnailWidth: 186,
    thumbnailHeight: 256,
    uploadMultiple: controller.allowMultipleUploadsValue,
    autoProcessQueue: true,
    parallelUploads: 1,
    autoQueue: true,
  });
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}
