import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reviewer--bulk-status-changer"
export default class extends Controller {
  static targets = [
    'globalStatus', // Dropdown that changes all statuses.
    'itemStatus',   // Individual dropdowns.
  ];

  connect() {
    // Update individual status items when global one changes.
    this.globalStatusTarget.addEventListener('change', event => {
      this.itemStatusTargets.forEach((element) => {
        element.selectedIndex = event.target.selectedIndex;
      });
    });
  }
}
