import { Controller } from '@hotwired/stimulus';
import Masonry from 'masonry-layout';

/**
 * Adds masonry layout to an element.
 *
 * Connects to data-controller="masonry-grid".
 */
export default class extends Controller {
  connect() {
    this.grid = new Masonry(this.element);

    // Reflow if a searchable grid is filtered.
    document.addEventListener('searchable-campaigns-grid:filter', (e) => {
      this.reflow();
    });

    document.addEventListener('searchable-campaigns-grid:reset', (e) => {
      this.reflow();
    });
  }

  reflow() {
    this.grid.layout();
  }
}
