import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification-settings"
export default class extends Controller {
  static targets = ['email'];

  connect() {
  }

  toggleEmailNotifications(e) {
    this.emailTargets.forEach(checkbox => checkbox.checked = e.target.checked);
  }
}
