import { Controller } from "@hotwired/stimulus";
import 'ckeditor4';

// Connects to data-controller="reviewer--text-post-review"
export default class extends Controller {
  static targets = ['caption'];

  static values  = {
    userId:      Number,
    userName:    String,
    userRole:    String,
    campaign:    Number,
    contentItem: Number,
    uploadUrl:   String,
  };

  connect() {
    let options = {
      toolbarStartupExpanded : true,
      removePlugins: 'elementspath',
      removeButtons: 'Underline,Subscript,Superscript,Table,HorizontalRule,SpecialChar,RemoveFormat,Anchor,Cut,Copy,Paste,PasteText,PasteFromWord',
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'insert',      groups: [ 'insert' ] },
        { name: 'links',       groups: [ 'links' ]  },
        { name: 'lite',        groups: [ 'lite' ] },
        { name: 'clipboard',   groups: [ 'undo' ] },
      ],
      height: '500px',
      lite: {},

      /*
       * Attaching and uploading images:
       *   - `uploadUrl` is used when dragging + dropping
       *   - `filebrowserUploadUrl` is used via "insert"
       */
      extraPlugins:         'uploadimage',
      uploadUrl:            this.uploadUrl(),
      removeDialogTabs:     'image:Link;image:advanced',
      filebrowserBrowseUrl: '',
      filebrowserUploadUrl: this.uploadUrl() + '?filebrowser=true',
      filebrowserUploadMethod: 'form',
    };

    // Reviewers and clients do not not have access to all tools.
    if ('admin' != this.userRoleValue) {
      options['lite'] = {
        commands: [LITE.Commands.TOGGLE_SHOW]
      };

      // Remove upload tools.
      delete options['extraPlugins'];
    }

    // Remove additional tabs from link dialog.
    CKEDITOR.on('dialogDefinition', function (ev) {
      const dialogName       = ev.data.name,
            dialogDefinition = ev.data.definition;

      if (dialogName === 'link') {
        dialogDefinition.removeContents('advanced');
        dialogDefinition.removeContents('target');
        dialogDefinition.removeContents('upload');
      }
    });

    // Create the editor instance.
    this.editor = CKEDITOR.replace(this.captionTarget, options);

    // Update LITE to track changes with app user info.
    this.editor.on(LITE.Events.INIT, (e) => {
      this.setCurrentUser(e);
    });

    // Remove additional tabs from link dialog.
    CKEDITOR.on('dialogDefinition', function (ev) {
      const dialogName       = ev.data.name,
            dialogDefinition = ev.data.definition;

      if (dialogName === 'link') {
        dialogDefinition.removeContents('advanced');
        dialogDefinition.removeContents('target');
        dialogDefinition.removeContents('upload');
      }
    });
  }

  disconnect() {
    this.editor.destroy();
  }

  setCurrentUser(e) {
    e.data.lite.setUserInfo({
      id:   this.userIdValue,
      name: this.userNameValue
    });
  }

  uploadUrl() {
    return this.uploadUrlValue
      .replace('CAMPAIGN_ID', this.campaignValue)
      .replace('CONTENT_ID',  this.contentItemValue);
  }
}
