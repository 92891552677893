import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";


// TODO: This is HORRENDOUSLY ugly. Rebuild it.

/**
 * Manages the popup asset manager.
 *
 * This is used by admins to replace assets that have been attached to a content
 * item.
 *
 * Connects to data-controller="asset-manager"
 */
export default class extends Controller {
  static values = {
    mode:           String, // Manager mode. Either 'add' or 'replace'.
    source:         String, // Action source. Usually something like `review_image_post`.
    itemType:       String, // Type of item that is being modified.
    itemId:         Number, // ID of the item that is being modified.
    addAssetUrl:    String, // URL used when uploading assets.
    removeAssetUrl: String, // URL used when removing uploaded assets.
    contentId:      Number, // ID of the content item being modified. <-- Remove this.
    originalId:     Number, // Optional ID of the original asset being replaced.
  }

  static targets = [
    'saveButton',  // Main save button.
    'assets',      // The list of available assets to attach.
    'placeholder', // Target that contains previews of uploaded items.
    'template',    // Preview template used by Dropzone.
    'videoPreview' // Hidden element to hold video previews.
  ];

  connect() {
    super.connect();

    // Disable dropzone auto discovery because it breaks things.
    Dropzone.autoDiscover = false;

    // Set up dropzone area.
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();

    // Disable the save button.
    this.saveButtonTarget.disabled = true;
  }

  disconnect() {
    this.dropZone.destroy();
  }

  // ----------------------------------------------------------------------
  // -- Item Selection
  // ----------------------------------------------------------------------

  toggle(e) {
    // Clear existing highlights.
    // TODO: This won't be present once we allow multiple selections.
    this.assetsTarget.querySelectorAll('.is-selected').forEach((item) => {
      item.classList.remove('is-selected');
    });

    // Highlight new image.
    e.currentTarget.parentNode.classList.add('is-selected');

    // Enable the save button.
    this.saveButtonTarget.disabled = false;
  }

  // ----------------------------------------------------------------------
  // -- Form Saving
  // ----------------------------------------------------------------------

  /**
   * Called when user clicks the "Save" button on the window.
   *
   * Adds additional information to the `button_to` form:
   *   - The mode (either 'add' or 'replace').
   *   - The id of the content asset being added.
   */
  save(e) {
    let form = e.target.form;

    // Clear any existing ids from the form.
    this.clearInputsFromForm(form, 'id');

    // TODO: Only call this if something is checked.
    if (this.hasAssetsTarget) {
      // Add a hidden input for the id.
      let checkedIds = this.getCheckedItemIds();
      checkedIds.forEach((id) => {
        this.addContentIdToForm(form, id);
      });
    }

    // If something was uploaded, add that id.
    if (this.hasUploadedAssets()) {
      // Add a hidden input for the uploaded item id.
      let uploadedIds = this.getUploadedItemIds();
      uploadedIds.forEach((id) => {
        this.addContentIdToForm(form, id);
      });
    }

    // Clear asset manager data from the form.
    this.clearInputsFromForm(form, 'mode');
    this.clearInputsFromForm(form, 'source');
    this.clearInputsFromForm(form, 'item_type');
    this.clearInputsFromForm(form, 'item_id');

    // Add asset manager data to the form.
    this.addInputToForm(form, 'mode', this.modeValue);
    this.addInputToForm(form, 'source', this.sourceValue);
    this.addInputToForm(form, 'item_type', this.itemTypeValue);
    this.addInputToForm(form, 'item_id', this.itemIdValue);

    // Add the original asset id (if replacing).
    if (this.hasOriginalIdValue) {
      this.clearInputsFromForm(form, 'asset_id');
      this.addInputToForm(form, 'asset_id', this.originalIdValue);
    }
  }

  getCheckedItemIds() {
    let checkedIds = [];

    this.assetsTarget.querySelectorAll('.is-selected').forEach((element) => {
      checkedIds.push(element.getAttribute('data-item-id'));
    });

    return checkedIds;
  }

  // TODO: Don't use `dz-complete` here.
  hasUploadedAssets() {
    return (this.placeholderTarget.querySelectorAll('.dz-complete').length > 0);
  }

  getUploadedItemIds() {
    let uploadedIds = [];

    this.placeholderTarget.querySelectorAll('.dz-complete').forEach((element) => {
      uploadedIds.push(element.getAttribute('data-asset-id'));
    });

    return uploadedIds;
  }

  clearInputsFromForm(form, name) {
    let ids = form.querySelectorAll("input[name='" + name + "']");
    ids.forEach((element) => {
      element.remove();
    });
  }

  addInputToForm(form, name, value) {
    let input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", name);
    input.setAttribute("value", value);

    form.appendChild(input);

    return input;
  }

  addContentIdToForm(form, id) {
    this.addInputToForm(form, 'id', id);
  }

  // Below code is used when multiple assets are selectable

  _save(e) {
    let form = e.target.form;

    // Clear any existing ids.
    let ids = form.querySelectorAll("input[name='content_asset_id[]']");
    ids.forEach((element) => {
      element.remove();
    });

    // Add a hidden input.
    let checkedIds = this.getCheckedItemIds();
    checkedIds.forEach((id) => {
      this.addContentIdToForm(form, id);
    });
  }

  _getCheckedItemIds() {
    let checkedIds = [];

    this.contentItemTargets.forEach((element) => {
      if (element.classList.contains('is-selected')) {
        checkedIds.push(element.getAttribute('data-item-id'));
      }
    });

    return checkedIds;
  }

  _addContentIdToForm(form, id) {
    let input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", "content_id[]");
    input.setAttribute("value", id);

    form.appendChild(input);

    return input;
  }

  // ----------------------------------------------------------------------
  // -- Internal functions
  // ----------------------------------------------------------------------

  bindEvents() {
    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);

      let assetId  = file.previewElement.getAttribute('data-asset-id');
      let assetUrl = this.removeAssetUrlValue.replace(':ASSET_ID', assetId);

      // Remove the file on the backend.
      let xhr = new XMLHttpRequest();
      xhr.open("DELETE", assetUrl, true);
      xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));
      xhr.send();

      // Toggle the preview controller.
      // this.maybeShowPreviewBox();
    });

    this.dropZone.on("addedfile", file => {
      // Remove the placeholder" if this the first image.
      // TODO: Use d-none here.
//      this.placeholderTarget.querySelector('.is-placeholder').style.display = 'none';

      // Make the "remove" button prettier.
      file.previewElement.querySelector('.dz-remove').classList.add('btn', 'btn-sm', 'btn-danger');

      // If video, generate a preview image.
      if (!this.isImageFile(file.type) && this.videoPreviewTarget.canPlayType(file.type)) {
        const fileURL = URL.createObjectURL(file);
        this.videoPreviewTarget.src = fileURL;

        // Set initial placeholder image
        file.previewElement.querySelector('img').src = '/empty.png';

        // Create a preview generator.
        let refreshPreview = () => {
          let canvas = document.createElement('canvas');
          canvas.width  = 186;//this.videoPreviewTarget.videoWidth;
          canvas.height = 256;//this.videoPreviewTarget.videoHeight;

          canvas.getContext('2d').drawImage(this.videoPreviewTarget, 0, 0, canvas.width, canvas.height);
          var dataURI = canvas.toDataURL();

          this.dropZone.emit('thumbnail', file, dataURI);

          // Clear everytihng out.
          URL.revokeObjectURL(file);
          this.videoPreviewTarget.src = '';

          this.videoPreviewTarget.removeEventListener('loadeddata', refreshPreview);
        };

        // Render the preview once the video element has loaded some data.
        this.videoPreviewTarget.addEventListener('loadeddata', refreshPreview);
      }

      /*
      // If video, generate a preview image.
      if (file.type.match(/mp4|MP4|mov/)) {
        var mockFile = { name: file.name };

        this.dropZone.options.addedfile.call(this.dropZone, mockFile);

        var src   = file.path;
        var video = document.createElement('video');
        video.src = src;

        video.addEventListener('loadeddata', () => {
          var canvas = document.createElement('canvas');
          canvas.width  = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          var dataURI = canvas.toDataURL('image/png');
          this.dropZone.emit("thumbnail", mockFile, dataURI);
        });

        this.dropZone.emit("complete", mockFile);
      }
      */
    });

    this.dropZone.on("success", (file, response) => {
      file.previewElement.setAttribute('data-asset-id', response.id);

      // Enable the save button.
      this.saveButtonTarget.disabled = false;
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('sending', (file, xhr, formData) => {
      formData.append('content_id', this.contentIdValue);
    });
  }

  isImageFile(fileType) {
    if ('image/png' === fileType) {
      return true;
    }

    if ('image/jpeg' === fileType) {
      return true;
    }

    return false;
  }

  hideFileInput() {
//    this.inputTarget.disabled = true;
//    this.inputTarget.style.display = "none";
  }

  // TODO: Move to a mixin.
  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.addAssetUrlValue;
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 512;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    previewsContainer: controller.placeholderTarget,
    previewTemplate: controller.templateTarget.innerHTML,
    thumbnailWidth: 186,
    thumbnailHeight: 256,
    uploadMultiple: false,
    autoProcessQueue: true,
    parallelUploads: 1,
    autoQueue: true,
  });
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}
