import { Controller } from "@hotwired/stimulus";

/**
 * Adds "Copy text to clipboard" functionality to any button and element.
 *
 * Connects to data-controller="copy-text-button"
 */
export default class extends Controller {
  static targets = ['text'];

  connect() {
  }

  copyText(event) {
    event.preventDefault();

    navigator.clipboard.writeText(this.textTarget.textContent);
  }
}
