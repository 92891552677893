import { Controller } from "@hotwired/stimulus";
import 'ckeditor4';

/**
 * Controller that admins, reviews, and clients use to edit image post content.
 *
 * This is mostly used to edit the caption.
 *
 * Connects to data-controller="reviewer--image-post-review"
 */
export default class extends Controller {
  static targets = [
    'caption',
    'wordCount',
    'charCount'
  ];

  static values  = {
    userId:   Number,  // ID of the user editing the content.
    userName: String,  // Display name of the user editing the content.
    userRole: String,  // Role name of the user editing the content.
  };

  connect() {
    let options = {
      toolbarStartupExpanded : true,
      removePlugins: 'elementspath',
      removeButtons: 'Cut,Copy,Paste,PasteText,PasteFromWord',
      toolbarGroups: [
        { name: 'lite',        groups: [ 'lite' ] },
        { name: 'clipboard',   groups: [ 'undo' ] },
      ],
    };

    // Reviewers and clients do not not have access to all tools.
    if ('admin' != this.userRoleValue) {
      options['lite'] = {
        commands: [LITE.Commands.TOGGLE_SHOW]
      };
    }

    // Create the editor instance.
    this.editor = CKEDITOR.replace(this.captionTarget, options);

    // Update LITE to track changes with app user info.
    this.editor.on(LITE.Events.INIT, (e) => {
      this.setCurrentUser(e);
    });
  }

  disconnect() {
    this.editor.destroy();
  }

  setCurrentUser(e) {
    e.data.lite.setUserInfo({
      id:   this.userIdValue,
      name: this.userNameValue
    });
  }
}
