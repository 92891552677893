import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="reviewer--content-preview"
export default class extends Controller {
  static targets = ['showChanges', 'hideChanges', 'preview', 'original'];

  connect() {
  }

  showChanges(e) {
    this.previewTarget.classList.remove('d-none');
    this.previewTarget.classList.add('d-block');

    this.originalTarget.classList.add('d-none');
    this.originalTarget.classList.remove('d-block');
  }

  hideChanges(e) {
    this.previewTarget.classList.add('d-none');
    this.previewTarget.classList.remove('d-block');

    this.originalTarget.classList.remove('d-none');
    this.originalTarget.classList.add('d-block');
  }
}
