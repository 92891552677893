import { Controller } from "@hotwired/stimulus";
import 'ckeditor4';

// Connects to data-controller="influencer--text-post-form"
export default class extends Controller {
  static targets = ['caption'];

  static values  = {
    campaign:    Number,
    contentItem: Number,
    uploadUrl:   String,
  };

  connect() {
    this.editor = CKEDITOR.replace( this.captionTarget, {
      toolbarStartupExpanded : true,
      removePlugins: 'elementspath, lite',
      removeButtons: 'Underline,Subscript,Superscript,Table,HorizontalRule,SpecialChar,RemoveFormat,Anchor',
      toolbarGroups: [
        { name: 'basicstyles', groups: [ 'basicstyles' ] },
        { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
        { name: 'insert',      groups: [ 'insert' ] },
        { name: 'links',       groups: [ 'links' ]  },
      ],
      height: '500px',

      /*
       * Attaching and uploading images:
       *   - `uploadUrl` is used when dragging + dropping
       *   - `filebrowserUploadUrl` is used via "insert"
       */
      extraPlugins:         'uploadimage',
      uploadUrl:            this.uploadUrl(),
      removeDialogTabs:     'image:Link;image:advanced',
      filebrowserBrowseUrl: '',
      filebrowserUploadUrl: this.uploadUrl() + '?filebrowser=true',
      filebrowserUploadMethod: 'form',
    } );

    // Remove additional tabs from link dialog.
    CKEDITOR.on('dialogDefinition', function (ev) {
      const dialogName       = ev.data.name,
            dialogDefinition = ev.data.definition;

      if (dialogName === 'link') {
        dialogDefinition.removeContents('advanced');
        dialogDefinition.removeContents('target');
        dialogDefinition.removeContents('upload');
      }
    });
  }

  disconnect() {
    this.editor.destroy();
  }

  uploadUrl() {
    return this.uploadUrlValue
      .replace('CAMPAIGN_ID', this.campaignValue)
      .replace('CONTENT_ID',  this.contentItemValue);
  }
}
