import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="reviewer--brand-reviews"
export default class extends Controller {
  static targets = ['notifyCheckbox', 'messageContainer'];

  connect() {
    this.toggleMessage();
  }

  toggleMessage() {
    if (this.notifyCheckboxTarget.checked) {
      this.messageContainerTarget.classList.remove('d-none');
    } else {
      this.messageContainerTarget.classList.add('d-none');
    }
  }
}
