import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="filterable-assets-list"
export default class extends Controller {
  static targets = ['typeDropdown', 'filterButton', 'downloadButton'];

  connect() {
    this.updateDownloadButtonUrl();

    this.typeDropdownTarget.addEventListener('change', (event) => {
      this.filterButtonTarget.click();
      this.updateDownloadButtonUrl();
    });
  };

  updateDownloadButtonUrl() {
    // Get the url without any filters.
    let url = this.downloadButtonTarget.href.split('?')[0];

    // If there is a filter, append it.
    if (this.typeDropdownTarget.value) {
      url += '?asset_type=' + this.typeDropdownTarget.value;
    }

    // Update the href.
    this.downloadButtonTarget.href = url;
  }
}
