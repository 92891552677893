import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--campaigns-table"
export default class extends Controller {
  static targets = ['statusDropdown', 'filterButton', 'table'];

  connect() {
    this.statusDropdownTarget.addEventListener('change', (event) => {
      this.filterButtonTarget.click();
    });
  };
}
