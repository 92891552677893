// Entry point for the build script in your package.json
import './add_jquery';
import "@hotwired/turbo-rails";
import "./controllers";
import * as bootstrap from "bootstrap";

window.bootstrap = bootstrap;

document.addEventListener('turbo:load', function () {
  // Enable Bootstrap tooltips.
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
