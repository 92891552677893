import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";
import { useAjaxHelpers } from "../mixins/ajax_helpers.js";

/**
 * Controller for handling a collection of items that can be manually reordered.
 *
 * All items must have a `data-item-id` element with their id. This is used when
 * sending the updated item list to a backend controller. The data field name can
 * be overridden with the `dataName` value, but each element must still have a
 * data attribute with an id.
 *
 * The controller will either use the element it was attached to, or the `items`
 * target if one is specified.
 *
 * Required configuration values:
 *
 *   - url :: The url to send the updated order to.
 *
 * Optional configuration values.
 *
 *   - itemsSelector  :: CSS selector for finding items within the list. Defaults
 *                       to all elements with a `data-item-id` attribute.
 *   - filter         :: CSS selector that will be excluded from drag/drop.
 *   - handle         :: CSS class of the grabbable handle.
 *   - selected       :: CSS class to apply to selected items.
 *   - group          :: Group identifier. Controllers with the same group name
 *                       can drag/drop between themselves. Normally want this to
 *                       be unique.
 *   - checkDraggable :: If true, will check `data-draggable` and will not allow
 *                       elements with TRUE to be dragged past elements that have
 *                       a false value.
 *   - dataName       :: Name of the data element used. Defaults to `data-item-id`
 *                       and also sets Sortable's `dataIdAttr` value.
 *
 * Connects to data-controller="reorderable-items-list"
 */
export default class extends Controller {
  static values = {
    url:            { type: String,  default: '' },               // The URL where saved orders are sent.
    itemsSelector:  { type: String,  default: '[data-item-id]' }, // Selector to find items in the list.
    filter:         { type: String,  default: '' },               // The filter CSS class.
    handle:         { type: String,  default: '' },               // The handle class.
    selectedClass:  { type: String,  default: 'is-selected' },    // The selected class.
    group:          { type: String,  default: 'shared' },         // Group identifier.
    checkDraggable: { type: Boolean, default: false },            // Optional class for locking item position.
    dataName:       { type: String,  default: 'data-item-id' },   // Optional data name override.
  };

  static targets = [
    'items',  // Optional items target. Will use the controller element if empty.
  ];

  connect() {
    // Set up mixins.
    useAjaxHelpers(this);

    // Create sortable.
    this.sortable = Sortable.create(this.getListElement(), {
      group:         this.groupValue,
      animation:     150,
      selectedClass: this.selectedClassValue,
      filter:        this.filterValue,
      handle:        this.handleValue,
      dataIdAttr:    this.dataNameValue,
      onEnd:         this.saveItemOrder.bind(this),
      onMove:        (e) => {
        // Do nothing if not checking.
        if (false === this.checkDraggableValue) {
          return true;
        }

        // Check the newly-sorted list.
        let skipNext = false;

        for (const node of e.target.childNodes) {
          // If we found our item on the previous loop, skip out.
          if (skipNext) {
            return true;
          }

          // Skip whitespace and non-draggable nodes.
          if ('#text' === node.nodeName || !node.hasAttribute('data-draggable')) {
            continue;
          }

          // Finish if we found our node.
          if (node === e.related) {
            skipNext = true;
          }

          if ('false' === node.getAttribute('data-draggable')) {
            return false;
          }
        }

        // No non-draggable items, so we can always sort.
        return true;
      },
    });
  }

  // ----------------------------------------------------------------------
  // -- Internal functions
  // ----------------------------------------------------------------------

  getListElement() {
    return (this.hasItemsTarget) ? this.itemsTarget : this.element;
  }

  /**
   * Get items inside the item target.
   */
  getItems() {
    return this.getListElement().querySelectorAll(this.itemsSelectorValue);
  }

  getItemOrder() {
    return Array.from(this.getItems()).map((element, i) => {
      return element.getAttribute(this.dataNameValue);
    });
  }

  saveItemOrder(event) {
     // Get updated list order.
    let order = this.getItemOrder();

    // Create payload.
    let formData = new FormData();
    order.forEach(i => {
      formData.append('ordering[]', i);
    });

    // Send to backend.
    this.sendPatchRequest(this.urlValue, formData);
  }
}
