import { Controller } from "@hotwired/stimulus";
import videojs from "video.js";

// Connects to data-controller="video-player"
export default class extends Controller {
  static targets = [
    'video',     // The video to play.
  ];

  connect() {
    if (!this.hasVideoTarget) {
      return;
    }

    this.videoPlayer = videojs(this.videoTarget, {
      controls: true,
      autoplay: false,
      fluid:    true,
      preload:  'auto',
    });
  }
}
