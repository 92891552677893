import { Controller } from "@hotwired/stimulus"

// Shows a loading indicator until a turbo request has finished.
// Connects to data-controller="loading-button"
export default class extends Controller {
  static targets = ['indicator', 'text'];

  connect() {
    this.form = this.element.querySelector('form');

    this.form.addEventListener('turbo:submit-start', (e) => {
      this.indicatorTarget.classList.add('d-block');
      this.indicatorTarget.classList.remove('d-none');

      this.textTarget.classList.add('d-none');
      this.textTarget.classList.remove('d-block');
    });

    this.form.addEventListener('turbo:submit-end', (e) => {
      this.indicatorTarget.classList.remove('d-block');
      this.indicatorTarget.classList.add('d-none');

      this.textTarget.classList.remove('d-none');
      this.textTarget.classList.add('d-block');
    });
  }
}
