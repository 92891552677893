import { Controller } from "@hotwired/stimulus";

/**
 * Adds mass-marking capability to a list of content items.
 *
 * Connects to data-controller="admin--mass-mark-content"
 */
export default class extends Controller {
  static targets = [
    'contentItem',    // Target to add to each markable content item.
    'summary',        // Contains the "X items marked" summary.
    'toolbarContent', // Contains the entire toolbar.
    'panel',          // Panel that contains action buttons. Hidden by default.
    'saveButton',     // Buttons that should be enabled/disabled when items are selected.
    'link',           // Optional links that will have ID's appended to them when changed.
  ];

  connect() {
    // Reset the form on first load.
    this.resetForm();

    // Reset the form when any save button is clicked.
    this.saveButtonTargets.forEach((element) => {
      if ('BUTTON' == element.nodeName) {
        element.form.addEventListener('turbo:submit-end', (e) => {
          this.resetForm();
        });
      }
    });
  }

  disconnect() {
    this.resetForm();
  }

  /**
   * Reset the form.
   *
   * Clears all checkboxes and marks the form as inactive.
   */
  resetForm() {
    this.active = false;
    this.clearCheckboxes();
    this.disableControls();
  }

  clearCheckboxes() {
    this.contentItemTargets.forEach((element) => {
      let checkbox = this.findCheckbox(element);

      if (checkbox) {
        checkbox.checked = false;
      }
    });
  }

  toggleControls(e) {
    if (e.target.checked) {
      this.enableControls();
      this.showPanel();
      this.hideToolbarContent();
      this.checkEverything();
      this.updateLinks();
    } else {
      this.disableControls();
      this.hidePanel();
      this.showToolbarContent();
      this.updateLinks();
    }
  }

  /**
   * Check all boxes.
   */
  checkEverything() {
    this.contentItemTargets.forEach((element) => {
      let checkbox = this.findCheckbox(element);

      if (checkbox) {
        element.classList.add('is-selected');
        checkbox.checked = true;
      }
    });

    // Update the "X items selected" label.
    this.updateSummary();
  }

  enableControls() {
    this.active = true;

    this.contentItemTargets.forEach((element) => {
      if (this.findCheckbox(element)) {
        element.classList.add('can-select');
      } else {
        element.classList.add('cannot-select');
      }
    });
  }

  disableControls() {
    this.active = false;

    this.contentItemTargets.forEach((element) => {
      element.classList.remove('can-select');
      element.classList.remove('cannot-select');
    });
  }

  enableSavebuttons() {
    this.saveButtonTargets.forEach((element) => {
      element.disabled = false;
      element.classList.remove('disabled');
    });
  }

  disableSaveButtons() {
    this.saveButtonTargets.forEach((element) => {
      element.disabled = true;
      element.classList.add('disabled');
    });
  }

  hideToolbarContent() {
    this.toolbarContentTarget.classList.add('d-none');
  }

  showToolbarContent() {
    this.toolbarContentTarget.classList.remove('d-none');
  }

  hidePanel() {
    this.panelTarget.classList.add('d-none');
  }

  showPanel() {
    this.panelTarget.classList.remove('d-none');
  }

  toggleMark(e) {
    if (!this.active) {
      return;
    }

    // Prevent the content link from firing.
    e.preventDefault();

    // Get the checkbox.
    let checkbox = this.findCheckbox(e.currentTarget);

    // Do nothing if no checkbox.
    if (!checkbox) {
      return;
    }

    if (checkbox.checked) {
      e.currentTarget.classList.remove('is-selected');
      checkbox.checked = false;
    } else {
      e.currentTarget.classList.add('is-selected');
      checkbox.checked = true;
    }

    // Update the "X items selected" label.
    this.updateSummary();
    this.updateLinks();
  }

  findCheckbox(target) {
    return target.querySelector("input[type='checkbox']");
  }

  updateSummary() {
    let labelText   = 'No items selected';
    const itemCount = this.countSelectedItems();

    if (itemCount == 0) {
      this.disableSaveButtons();
    } else {
      this.enableSavebuttons();
    }

    if (itemCount == 1) {
      labelText = '1 item selected';
    } else if (itemCount > 1) {
      labelText = itemCount + ' items selected';
    }

    this.summaryTarget.innerHTML = labelText;
  }

  countSelectedItems() {
    // Count selected checkboxes.
    let itemCount = 0;

    this.contentItemTargets.forEach((element) => {
      if (element.classList.contains('is-selected')) {
        itemCount += 1;
      }
    });

    return itemCount;
  }

  updateLinks() {
    this.addIdsToLinks();
  }

  addIdsToLinks() {
    if (!this.hasLinkTarget) {
      return;
    }

    // Clear any existing ids.
    this.linkTargets.forEach((element) => {
      let link       = element.getAttribute('data-original-href');
      let checkedIds = this.getCheckedItemIds();

      // TODO: Don't assume that the link doesn't have a ? in it already.
      link += '?';

      checkedIds.forEach((id) => {
        link += 'id[]=' + id + '&';
      });

      element.href = link;
    });
  }

  submitChanges(e) {
    let form = e.target.form;

    // Clear any existing ids.
    let ids = form.querySelectorAll("input[name='content_id[]']");
    ids.forEach((element) => {
      element.remove();
    });

    // Add a hidden input.
    let checkedIds = this.getCheckedItemIds();
    checkedIds.forEach((id) => {
      this.addContentIdToForm(form, id);
    });
  }

  getCheckedItemIds() {
    let checkedIds = [];

    this.contentItemTargets.forEach((element) => {
      if (element.classList.contains('is-selected')) {
        checkedIds.push(element.getAttribute('data-item-id'));
      }
    });

    return checkedIds;
  }

  addContentIdToForm(form, id) {
    let input = document.createElement("input");

    input.setAttribute("type", "hidden");
    input.setAttribute("name", "content_id[]");
    input.setAttribute("value", id);

    form.appendChild(input);

    return input;
  }
}
