import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="admin--users-table"
export default class extends Controller {
  static targets = ['searchTerm', 'roleDropdown', 'filterButton', 'table'];

  connect() {
    return;
//    this.filterButtonTarget.classList.add('d-none');
    this.roleDropdownTarget.addEventListener('change', (event) => {
      this.filterButtonTarget.click();
    });

    this.searchTermTarget.addEventListener('keyup', (event) => {
      this.filterButtonTarget.click();
    });
  };

  disconnect() {
    this.filterButtonTarget.classList.remove('d-none');
  };
}
