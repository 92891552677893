import { Controller } from "@hotwired/stimulus";
import TomSelect from 'tom-select';

// Connects to data-controller="admin--link-campaign-form"
export default class extends Controller {
  static targets = ['remoteCampaigns'];

  connect() {
    this.dropdown = new TomSelect(this.remoteCampaignsTarget, {
      create: false,
      maxOptions: null,
      sortField: {
        field: "text",
        direction: "asc"
      }
    });
  }

  disconnect() {
    this.dropdown.destroy();
  }
}
