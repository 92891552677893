import { Controller } from "@hotwired/stimulus";

/**
 * Controller for handling Harvest-style "Are you sure?" buttons.
 *
 * Hides the target button and shows a message container with a confirmation
 * button. Will also disable the submit button during form submission.
 *
 * Connects to data-controller="confirmation-button"
 */
export default class extends Controller {
  static targets = ["buttonContainer", "messageContainer", "submitButton"];

  connect() {

  }

  showConfirmation(e) {
    e.preventDefault();

    this.hideButtonContainer();
    this.showMessageContainer();
  }

  cancelSubmission(e) {
    e.preventDefault();

    this.showButtonContainer();
    this.hideMessageContainer();
  }

  hideButtonContainer() {
    this.buttonContainerTarget.classList.add('d-none');
  }

  showButtonContainer() {
    this.buttonContainerTarget.classList.remove('d-none');
  }

  hideMessageContainer() {
    this.messageContainerTarget.classList.add('d-none');
  }

  showMessageContainer() {
    this.messageContainerTarget.classList.remove('d-none');
  }
}
