import { Controller } from "@hotwired/stimulus";
import Masonry from 'masonry-layout';

let debounce = require("lodash/debounce");
let Mark     = require('mark.js');

/**
 * Allow a grid of campaigns to be filtered by text and/or status.
 *
 * Uses `mark.js` to search the title and description, then hides campaigns that
 * aren't marked up.
 *
 * Connects to data-controller="searchable-campaigns-grid".
 */
export default class extends Controller {
  static targets = [
    'toolbar',         // The toolbar container.
    'searchTerm',      // The campaign name/description to search for.
    'statusFilter',    // The list of statuses to filter by.
    'campaignsGrid',   // The container of campaigns.
    'campaign',        // Each element that contains a campaign.
  ];

  /**
   * Initialize the controller.
   *
   * Runs the filters and hides/hows the toolbar if any search term or dropdown
   * selection has made.
   */
  connect() {
    // Debounce input to the text filter so that it only triggers a re-filter
    // every 100ms when the user is typing.
    this.filterAction = debounce(this.filter, 100).bind(this);
    this.highlighter  = new Mark(this.campaignsGridTarget);

    // Run the initial filter.
    this.filter();

    // Expand the toolbar if anything is entered.
    this.maybeExpandFilterToolbar();
  }

  /**
   * Run the filter.
   *
   * Marks up any matching content and hides anything that doesn't match.
   */
  filter() {
    const keyword      = this.searchTermTarget.value;
    const filterText   = keyword.toUpperCase();
    const filterStatus = this.statusFilterTarget.options[this.statusFilterTarget.selectedIndex].value;

    // Highlight found phrases.
    this.highlighter.unmark({
      done: () => {
        this.highlighter.mark(keyword, {
          exclude: [
            '.card-footer *',
            'h5 .badge',
          ]
        });
      }
    });

    // Filter the list.
    this.campaignTargets.forEach((element) => {
      let canShow = true;

      canShow = canShow && this.campaignIsHighlighted(element, filterText);
      canShow = canShow && this.campaignStatusMatches(element, filterStatus);

      element.classList.toggle('d-none', !canShow);
    });

    // Let other controllers know something has changed.
    this.dispatch('filter', { detail: { filterText: filterText, filterStatus: filterStatus } });
  }

  /**
   * Expand the filter toolbar if a filter is running.
   *
   * Checks if filter text is entered or if something from the status dropdown
   * has been selected.
   *
   * Does nothing if there isn't a toolbar.
   */
  maybeExpandFilterToolbar() {
    const keyword      = this.searchTermTarget.value;
    const filterStatus = this.statusFilterTarget.options[this.statusFilterTarget.selectedIndex].value;

    // Don't do anything if nothing has been entered.
    if ('' === keyword && '' === filterStatus) {
      return;
    }

    // Don't do anything if there's no toolbar.
    if (!this.hasToolbarTarget) {
      return;
    }

    // Expand the filter toolbar.
    this.toolbarTarget.classList.add('show');
  }

  /**
   * Check if any campaign elements have highlights.
   */
  campaignIsHighlighted(element, filterText) {
    // Empty text matches everything.
    if ('' === filterText) {
      return true;
    }

    // Just check if something is marked up.
    return (element.querySelectorAll('mark').length > 0);
  }

  /**
   * Check if any campaigns match the filter status.
   */
  campaignStatusMatches(element, filterStatus) {
    // Empty status matches everything.
    if ('' === filterStatus) {
      return true;
    }

    // Get the status badge.
    const campaignStatusBadge = element.querySelector('.badge');

    // Campaign matches if badge is missing.
    if (!campaignStatusBadge) {
      return true;
    }

    return (campaignStatusBadge.textContent.toLowerCase() === filterStatus.toLowerCase());
  }

  /**
   * Reset the filter.
   *
   * Clears the filter text, resets the dropdown, removes highlights, and shows
   * all campaigns.
   *
   * Emits `reset` event so masonry can reflow.
   */
  reset(e) {
    e.preventDefault();

    // Clear the filter box and selected status.
    this.searchTermTarget.value       = '';
    this.statusFilterTarget.selectedIndex = 0;

    // Reset highlights.
    this.highlighter.unmark();

    // Research hiding/showing.
    this.campaignTargets.forEach((element) => {
      element.classList.remove('d-none');
    });

    // Let other controllers know everything was reset.
    this.dispatch('reset');
  }
}
