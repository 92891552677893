export const useAjaxHelpers = (controller) => {
  Object.assign(controller, {
    getPageMetaValue(name) {
      const element = document.head.querySelector(`meta[name="${name}"]`);
      if (!element) {
        return false;
      }

      return element.getAttribute("content");
    },

    /**
     * Send form data to a url as a PATCH request.
     *
     * @param url The URL to send to.
     * @param formData The FormData object to send.
     */
    sendPatchRequest(url, formData) {
      let xhr = new XMLHttpRequest();
      xhr.open("PATCH", url, true);
      xhr.setRequestHeader("X-CSRF-Token", this.getPageMetaValue("csrf-token"));
      xhr.send(formData);
    }
  });
};
