import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import autosize from "autosize";

// import { has_dropzone } from "./mixins/has_dropzone.js";

// Connects to data-controller="influencer--image-post-form"
export default class extends Controller {
  static values = {
    addAssetUrl:    String,
    removeAssetUrl: String,
    contentId:      Number,
  };

  static targets = ["input", "placeholder", "template", "form", "videoPreview"];

  // Dropzone configuration.
  // TODO: Configure this on the controller side.
  maxFiles = 10;

  /**
   * Form setup.
   *
   * Initialize dropzone and set-up auto-sizing
   */
  connect() {
    super.connect();

    // has_dropzone(this);

    // Disable dropzone auto discovery because it breaks things.
    Dropzone.autoDiscover = false;

    // Set up dropzone area.
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
  }

  disconnect() {
    this.dropZone.destroy();
  }


  // ----------------------------------------------------------------------
  // -- Events
  // ----------------------------------------------------------------------

  triggerAddFile(event) {
    this.dropZone.hiddenFileInput.click();
  };

  /**
   * Remove an attached file that was previously added.
   *
   * This is slightly different from the main dropzone delete as it needs to
   * manually remove its own element.
   */
  triggerRemoveFile(event) {
    event.preventDefault();
    event.stopPropagation();

    // TODO: Find a better way to do this. Should not be building requests like this.
    let xhr = new XMLHttpRequest();
    xhr.open("DELETE", event.target.href, true);
    xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));

    // Remove the image.
    event.target.closest('.dz-image-preview').remove();

    // Refresh the image list.
    this.maybeShowPreviewBox();

    // Send the request
    xhr.send();
  };

  maybeShowPreviewBox() {
    // Count items
    if (this.placeholderTarget.querySelector('.dz-image-preview') == null) {
      this.showPreviewBox();
    } else {
      this.hidePreviewBox();
    }
  }

  showPreviewBox() {
    // TODO: preview item should be a dedicated target.
    this.placeholderTarget.querySelector('.is-placeholder').style.display = 'block';
  }

  hidePreviewBox() {
    // TODO: preview item should be a dedicated target.
    this.placeholderTarget.querySelector('.is-placeholder').style.display = 'none';
  }



  // ----------------------------------------------------------------------
  // -- Internal functions
  // ----------------------------------------------------------------------

  bindEvents() {
    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);

      let assetId  = file.previewElement.getAttribute('data-asset-id');
      let assetUrl = this.removeAssetUrlValue.replace(':ASSET_ID', assetId);

      // Remove the file on the backend.
      let xhr = new XMLHttpRequest();
      xhr.open("DELETE", assetUrl, true);
      xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));
      xhr.send();

      // Toggle the preview controller.
      this.maybeShowPreviewBox();
    });

    this.dropZone.on("addedfile", file => {
      // Remove the placeholder" if this the first image.
      // TODO: Use d-none here.
      this.placeholderTarget.querySelector('.is-placeholder').style.display = 'none';

      // Make the "remove" button prettier.
      file.previewElement.querySelector('.dz-remove').classList.add('btn', 'btn-sm', 'btn-danger');

      // If video, generate a preview image.
      if (this.videoPreviewTarget.canPlayType(file.type)) {
        const fileURL = URL.createObjectURL(file);
        this.videoPreviewTarget.src = fileURL;

        // Set initial placeholder image
        file.previewElement.querySelector('img').src = '/empty.png';

        // Create a preview generator.
        let refreshPreview = () => {
          let canvas = document.createElement('canvas');
          canvas.width  = 186;//this.videoPreviewTarget.videoWidth;
          canvas.height = 256;//this.videoPreviewTarget.videoHeight;

          canvas.getContext('2d').drawImage(this.videoPreviewTarget, 0, 0, canvas.width, canvas.height);
          var dataURI = canvas.toDataURL();

          this.dropZone.emit('thumbnail', file, dataURI);

          // Clear everytihng out.
          URL.revokeObjectURL(file);
          this.videoPreviewTarget.src = '';

          this.videoPreviewTarget.removeEventListener('loadeddata', refreshPreview);
        };

        // Render the preview once the video element has loaded some data.
        this.videoPreviewTarget.addEventListener('loadeddata', refreshPreview);
      }

      /*
      // If video, generate a preview image.
      if (file.type.match(/mp4|MP4|mov/)) {
        var mockFile = { name: file.name };

        this.dropZone.options.addedfile.call(this.dropZone, mockFile);

        var src   = file.path;
        var video = document.createElement('video');
        video.src = src;

        video.addEventListener('loadeddata', () => {
          var canvas = document.createElement('canvas');
          canvas.width  = video.videoWidth;
          canvas.height = video.videoHeight;
          canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
          var dataURI = canvas.toDataURL('image/png');
          this.dropZone.emit("thumbnail", mockFile, dataURI);
        });

        this.dropZone.emit("complete", mockFile);
      }
      */
    });

    this.dropZone.on("success", (file, response) => {
      file.previewElement.setAttribute('data-asset-id', response.id);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on('sending', (file, xhr, formData) => {
      formData.append('content_id', this.contentIdValue);
    });
  }

  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  // TODO: Move to a mixin.
  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.addAssetUrlValue;
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 1;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 256;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    previewsContainer: controller.placeholderTarget,
    previewTemplate: controller.templateTarget.innerHTML,
    thumbnailWidth: 186,
    thumbnailHeight: 256,
    upploadMultiple: true,
    autoProcessQueue: true,
    parallelUploads: 1,
    autoQueue: true,
  });
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}
