import { Controller } from "@hotwired/stimulus";
import 'ckeditor4';

/**
 * Controller that admins, reviews, and clients use to edit story post content.
 *
 * This is mostly used to edit the captions.
 *
 * Connects to data-controller="reviewer--story-post-review"
 */
export default class extends Controller {
  static targets = [
    'caption',
    'frames',      // Container of all story frames.
    'rowTemplate', // Template for inserting caption rows.
  ];

  static values  = {
    userId:   Number,
    userName: String,
    userRole: String,
  };

  connect() {
    this.editors = [];
  }

  disconnect() {
    this.editors.forEach((editor) => { editor.destroy(); });
  }

  getEditorOptions() {
    if (typeof this.options === 'undefined') {
      this.options = {
        toolbarStartupExpanded : true,
        removePlugins: 'elementspath',
        removeButtons: 'Cut,Copy,Paste,PasteText,PasteFromWord',
        toolbarGroups: [
          { name: 'lite',        groups: [ 'lite' ] },
          { name: 'clipboard',   groups: [ 'undo' ] },
        ],
      };

      // Reviewers and clients do not not have access to all tools.
      if ('admin' != this.userRoleValue) {
        this.options['lite'] = {
          commands: [LITE.Commands.TOGGLE_SHOW]
        };
      }
    }

    return this.options;
  }

  setCurrentUser(e) {
    e.data.lite.setUserInfo({
      id:   this.userIdValue,
      name: this.userNameValue
    });
  }

  removeParentCaption(e) {
    e.preventDefault();

    let frame = e.target.closest('.story-post-frame');

    this.setDeletedFlag(frame, 1);
    frame.classList.add('is-deleted');
  }

  undoCaptionDeletion(e) {
    e.preventDefault();

    let frame = e.target.closest('.story-post-frame');

    this.setDeletedFlag(frame, 0);
    frame.classList.remove('is-deleted');
  }

  setDeletedFlag(frame, value) {
    // Find the field.
    let index = frame.getAttribute('data-index');
    let id    = 'content_item_story_post_content_captions_attributes_' + index + '_deleted';
    let flag  = frame.querySelector('#' + id);

    flag.value = value;
  }

  addStoryFrame(e) {
    e.preventDefault();

    this.addEmptyRow();
  }

  captionTargetConnected(caption) {
    let editor = CKEDITOR.replace(caption, this.getEditorOptions());

    // Update LITE to track changes with app user info.
    editor.on(LITE.Events.INIT, (e) => {
      this.setCurrentUser(e);
    });

    if (typeof this.editors === 'undefined') {
      this.editors = [];
    }

    this.editors.push(editor);
  }

  getStoryFrames() {
    return this.framesTarget.querySelectorAll('.story-post-frame:not(.story-post-frame--placeholder)');
  }
}
