import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="asset-gallery"
export default class extends Controller {
  static targets = ['thumbnails', 'preview'];

  connect() {
    if (this.hasThumbnailsTarget) {
      this.thumbnailsTarget.querySelector('a').classList.add('is-selected');
    }
  }

  select(e) {
    // Remove "Is Selected" class from others.
    this.thumbnailsTarget.querySelector('a.is-selected').classList.remove('is-selected');

    // Get the url of the full image.
    e.currentTarget.classList.add('is-selected');
  }
}
