import { Controller } from "@hotwired/stimulus";

/**
 * Controller for client mass mark feature.
 *
 * This is slightly different from the admin one as there is no other content in
 * the toolbar, and the save button links to a modal window instead of
 * submitting a form.
 *
 * Connects to data-controller="client--mass-mark-content"
 */
export default class extends Controller {
  static targets = [
    'summary',
    'panel',
    'saveButton',
    'contentItem',
  ];

  connect() {
    // Reset everything.
    this.active = false;
    this.clearCheckboxes();

    // Add a listener for the form.
//    this.saveButtonTarget.form.addEventListener('turbo:submit-end', (e) => {
//      this.active = false;
//      this.clearCheckboxes();
//      this.disableControls();
    //    });
  }

  clearCheckboxes() {
    this.contentItemTargets.forEach((element) => {
      let checkbox = this.findCheckbox(element);

      if (checkbox) {
        checkbox.checked = false;
      }
    });
  }

  toggleControls(e) {
    if (e.target.checked) {
      this.enableControls();
      this.showPanel();
      this.checkEverything();
    } else {
      this.disableControls();
      this.hidePanel();
    }
  }

  checkEverything() {
    this.contentItemTargets.forEach((element) => {
      let checkbox = this.findCheckbox(element);

      if (checkbox) {
        element.classList.add('is-selected');
        checkbox.checked = true;
      }
    });

    // Update the "X items selected" label.
    this.updateSummary();
  }

  enableControls() {
    this.active = true;

    this.contentItemTargets.forEach((element) => {
      if (this.findCheckbox(element)) {
        element.classList.add('can-select');
      } else {
        element.classList.add('cannot-select');
      }
    });
  }

  disableControls() {
    this.active = false;

    this.contentItemTargets.forEach((element) => {
      element.classList.remove('can-select');
      element.classList.remove('cannot-select');
    });
  }

  enableSavebuttons() {
    this.saveButtonTargets.forEach((element) => {
      element.disabled = false;
    });
  }

  disableSaveButtons() {
    this.saveButtonTargets.forEach((element) => {
      element.disabled = true;
    });
  }

  hidePanel() {
    this.panelTarget.classList.add('d-none');
  }

  showPanel() {
    this.panelTarget.classList.remove('d-none');
  }

  toggleMark(e) {
    if (!this.active) {
      return;
    }

    // Prevent the content link from firing.
    e.preventDefault();

    // Get the checkbox.
    let checkbox = this.findCheckbox(e.currentTarget);

    // Do nothing if no checkbox.
    if (!checkbox) {
      return;
    }

    if (checkbox.checked) {
      e.currentTarget.classList.remove('is-selected');
      checkbox.checked = false;
    } else {
      e.currentTarget.classList.add('is-selected');
      checkbox.checked = true;
    }

    // Update the "X items selected" label.
    this.updateSummary();
  }

  findCheckbox(target) {
    return target.querySelector("input[type='checkbox']");
  }

  updateSummary() {
    let labelText   = 'No items selected';
    const itemCount = this.countSelectedItems();

    if (itemCount == 0) {
      this.disableSaveButtons();
    } else {
      this.enableSavebuttons();
    }

    if (itemCount == 1) {
      labelText = '1 item selected';
    } else if (itemCount > 1) {
      labelText = itemCount + ' items selected';
    }

    this.summaryTarget.innerHTML = labelText;
  }

  countSelectedItems() {
    // Count selected checkboxes.
    let itemCount = 0;

    this.contentItemTargets.forEach((element) => {
      if (element.classList.contains('is-selected')) {
        itemCount += 1;
      }
    });

    return itemCount;
  }

  submitChanges(e) {
    let link = e.target;

    // Add initial space for arg.
    link.href += '?';

    // Append each id to the url?
    let checkedIds = this.getCheckedItemIds();
    checkedIds.forEach((id) => {
      this.addContentIdToLink(link, id);
    });
  }

  getCheckedItemIds() {
    let checkedIds = [];

    this.contentItemTargets.forEach((element) => {
      if (element.classList.contains('is-selected')) {
        checkedIds.push(element.getAttribute('data-item-id'));
      }
    });

    return checkedIds;
  }

  addContentIdToLink(link, id) {
    link.href += 'content_id[]=' + id + '&';
  }
}
