import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

// Connects to data-controller="admin--remote-modal"
export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element, {
      keyboard: false
    });
    this.modal.show();
  }

  /**
   * Close the modal
   */
  closeWhenFormSubmitted() {
    this.modal.hide();
  }

  disconnect() {
    this.modal.hide();
    this.modal.dispose();
  }

  hideBeforeRender(event) {
    this.modal.hide();
  }

  isModalVisible() {
    return this.modal._isShown;
  }
}
