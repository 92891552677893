import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="influencer--campaign-assets"
export default class extends Controller {
  static targets = ["buttonContainer", "messageContainer", "deleteButton"];

  connect() {
  }

  showConfirmation(e) {
    e.preventDefault();

    this.hideButtonContainer();
    this.showMessageContainer();
  }

  cancelDeletion(e) {
    e.preventDefault();

    this.showButtonContainer();
    this.hideMessageContainer();
  }

  // Disable
  disableDuringSubmit(e) {
    this.submitButtonTarget.disabled = true;
  }

  hideButtonContainer() {
    this.buttonContainerTarget.classList.add('d-none');
  }

  showButtonContainer() {
    this.buttonContainerTarget.classList.remove('d-none');
  }

  hideMessageContainer() {
    this.messageContainerTarget.classList.add('d-none');
  }

  showMessageContainer() {
    this.messageContainerTarget.classList.remove('d-none');
  }

}
