import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="notifications"
export default class extends Controller {
  static targets = ['bell'];
  static values  = {
    readReceiptsUrl: String
  };

  // Set up event listeners.
  connect() {
    this.boundMarkNotificationsAsRead = this.markNotificationsAsRead.bind(this);

    this.element.addEventListener('hidden.bs.dropdown', this.boundMarkNotificationsAsRead);
  }

  disconnect() {
    this.element.removeEventListener('hidden.bs.dropdown', this.boundMarkNotificationsAsRead);
  }

  markNotificationsAsRead(e) {
    // Ensure it's the correct dropdown.
    if (e.currentTarget !== this.element) {
      return;
    }

    // Send the POST request.
    let xhr = new XMLHttpRequest();
    xhr.open("POST", this.readReceiptsUrlValue, true);
    xhr.setRequestHeader("X-CSRF-Token", getMetaValue("csrf-token"));

    // Send the request.
    xhr.send();

    // Update the bell.
    this.bellTarget.innerHTML = '<i class="bi bi-bell">';
  }
}

function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}
